<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/grade/cores/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Grade de cor
            <v-spacer />
            <v-text-field
              v-model="filter.Term"
              label="Buscar"
              single-line
              hide-details
              @keyup.enter="load(true)"
            />
            <v-btn
              color="primary"
              @click="load(true)"
            >
              Buscar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="cores"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:item.action="{ item }">
              <router-link :to="`/pages/grade/cores/${item.corId}`">
                <v-icon
                  class="mr-2"
                  color="info"
                >
                  mdi-pencil
                </v-icon>
              </router-link>
              <v-icon
                color="error"
                @click="destroy(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          A cor será deletada. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      itemselected: {},
      cores: [],
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Ações', value: 'action', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      dialog: false,
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load (resetPage = false) {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = resetPage ? 1 : this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/produto/cor', { params: { query } })
          .then(resp => {
            this.cores = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/produto/cor/${this.itemselected.corId}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.load()
          })
          .catch(err => {
            try {
              const msg = err.response.data.message
              this.$toast.error(msg)
              this.dialog = false
            } catch (error) {
              this.$toast.error('Falha ao realizar operação')
            }
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
</style>
